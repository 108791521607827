import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import UserCard from "../components/UserCard";
import { db } from "../firebaseConfig";
import "./Directory.scss";

const Directory = ({ setCurrentPage, setSlidePosition }) => {
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    try {
      const usersRef = collection(db, "users");
      const alphabeticalOrderQuery = query(
        usersRef,
        orderBy("first_name", "asc")
      );
      const usersDocs = await getDocs(alphabeticalOrderQuery);
      const user = usersDocs.docs.map((doc) => doc.data());
      console.log(user);
      setUsers(user);
      console.log(users);
    } catch {
      console.log("Sorry couldn't get users");
    }
  };

  useEffect(() => {
    setCurrentPage("directory");
    setSlidePosition(80 * 3);
    getUsers();
  }, []);

  const [userInput, setUserInput] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const handleUserSearch = () => {
    const filteredUsers = users.filter(
      (user) =>
        user.first_name?.toLowerCase().includes(userInput?.toLowerCase()) ||
        user.last_name?.toLowerCase().includes(userInput?.toLowerCase())
    );
    return filteredUsers;
  };

  const displayedUsers = userInput ? handleUserSearch() : users;
  return (
    <div className="directory fade-in">
      <div className="directory-header">
        <h1 className="page-title">Find a warrior</h1>
        <input
          type="text"
          className="search directory-search"
          placeholder="Search for user"
          onChange={(e) => {
            setUserInput(e.target.value);
            handleUserSearch();
          }}
        ></input>
      </div>

      <div className="card-container">
        {displayedUsers.map((user) => (
          <UserCard
            firstName={user.first_name}
            lastName={user.last_name}
            role={user.role}
            userId={user.uid}
            image={user.headshot}
            imageSize="100"
          />
        ))}
      </div>
    </div>
  );
};

export default Directory;
