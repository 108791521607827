import React from 'react';


const WelcomeModal = () => {
    return (
        <div>
          <h1>Welcome to self tape battle lol</h1>  
        </div>
    );
};

export default WelcomeModal;